.texts-bck { 
    background: url(../../img/u.jpeg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: contain;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

/* .texts-bck {
    cursor: pointer;
} */

.list > h1 {
    font-size: 19.5px;
}

.list > li > a {
    font-size: 15px;
}

.box > ul {
    list-style: none;
}

.list > li > a, .list > h1 {
    background-color: white;
}

.list > li {
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}

#sky {
    position: absolute;
    width: 100vw;
    top: -300px;
}