.welcome-bck { 
    background: url(../../img/i.jpeg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: contain;
    background-color: white;
    height: 100vh;
    width: 100vw;
}

#sound {
    z-index: 100;
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding: 2rem;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.links {
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
}

a {
    font-size: 19.5px;
    position: relative;
    z-index: 100;
    background-color: transparent;
}

.links > h2 > a:hover, .links > div > h2 > a:hover {
    background-image: none;
    cursor: url('../../img/belly-cursor.png'), auto;
}

#gate {
    /* margin-top: -1rem; */
    position: relative;
    z-index: -100;
    height: 29vh;
    background-image: url(../../img/gate.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#txt {
    background-color: rgba(255, 255, 255, 0.701);
}