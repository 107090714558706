.thread {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    /* background-image: url(../../img/bird.gif); */
    background-repeat: no-repeat;
    background-position: left;
}

.tweets {
    z-index: 100;
    top: 0;
    overflow: hidden;
    color: white;
    /* list-style: none; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 2rem;
}

.tweets > li {
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    padding-bottom: 2rem;
    border-style: solid;
    border-color: crimson;
    border-width: .1px;
    padding: 1.5rem;
    margin: 3rem;
    position: relative;
    background-color: black;
    z-index: 100;
}

#end {
    display: flex;
    flex-direction: row;
    justify-content: center;
}